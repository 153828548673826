import React from 'react';
import styled from 'styled-components';
import HeroBg from '../assets/hero.png';
import Content from './Layout/Content';
import RightActive from '../assets/arrow-right2.png';
import { media } from '../Ui/text-sizes';
import './hero.less';
const Hero = ({ children, onPress }) => {
    return <div className={'StyledContainer'}>
        <video style={{
            position: 'absolute',
            minWidth: '100%',
            minHeight: '100vh',
            top: 0,
            zIndex: 1
        }} poster={'https://pxp7hn3b3jxqy3.data.mediastore.us-east-1.amazonaws.com/ezgif-frame-001.jpg'} autoPlay muted loop id="myVideo">
            <source src="https://pxp7hn3b3jxqy3.data.mediastore.us-east-1.amazonaws.com/hero-video.mp4" type="video/mp4" />
        </video>
        <div className={'overlay'}>
            <div className={'container-hero'}>
                {children}
                <div className={'ArrowSection'}>
                    <img src={RightActive} width={45} height={45} onClick={onPress} />
                </div>
            </div>
        </div>

    </div >
};

export default Hero;
