import { Button, Carousel, Col, Row } from 'antd';
import Text from '../Ui/Typography/Text';
import React, { memo, useRef, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { Card } from 'antd';
import { media } from '../Ui/text-sizes';
import RightActive from '../assets/right-active2.png';
import RightHover from '../assets/right-big-hover.png';
import LeftActive from '../assets/left-active2.png';
import Frame from '../assets/frame.png';
import LeftHover from '../assets/left-big-hover.png';
import L2wMain from '../assets/l2w-main2.png';
const GlobalStyle = createGlobalStyle`
  .footer{
    margin-top: 50px;
    margin-left: -8px;
    padding: 0 !important;
  }
  .textPartner{
    z-index:5;
    background-color: white;
    width: 100%;
    max-height: 200px;
    height: 100%;
    padding-right: 10px;
    margin-top: 0px;
     @media (max-width:900px) {
          
      background-color:#D9D9D9 !important; 
    }
  }
  .ant-carousel {
    .slick-list {
      padding: 0 !important;
    }
    
    .slick-dots-bottom {
     display: none !important;
    }
  }

  .item-testimonials {
    padding-right: 20px;
    ${media.down('sm')} {
      padding-right: 0px;
    }
  }
  
  .carousel-item {
     ${media.down('sm')} {
      height: auto;
    }
    
  }
  
  .card {
  
   background-color: white;
    border: none;
    z-index:5;
    margin-top: 87px;
    padding: 0 !important;

     @media (max-width:900px) {
          
      background-color:#D9D9D9 !important; 
    }
    ${media.down('sm')} {
      // padding: 20px !important;     
     }
     
    
    &__header {
      height: 50px;
      margin-bottom: 23px;
      padding: 0 !important;
      ${media.down('sm')} {
         // padding: 20px !important;     
        }
    }
    
    &__logo {
      max-width: 200px;
      max-height: 50px;
      padding: 0 !important;
    }
    
    &__personal-info {
      margin-left: 8px;
      padding: 0 !important;
    }
    
    .ant-card-body {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 5px !important;
      
      ${media.down('sm')} {
        padding: 0px !important;
      }
      
      &:before,
      &:after {
        display: none;
      }
    }
  }
  
  .carousel-mobile {
    display: none;
    
    ${media.down('sm')} {
      display: block !important;
    }
  }
  
  .carousel-item-desktop {
    padding: 0 15px;
    margin-bottom: 20px;
    
    ${media.down('sm')} {
      padding: 0;
    }
  }
  
  .carousel-desktop {
    &-arrows {
      margin-bottom: 30px;
    }
  }
  .next-link{
    .next-img{
        width: 154px;
        height: 53px;
        right: 852px;
        float:left;
        cursor: pointer;
        background-size: contain;
        position: absolute;
        top: 40%;
        background-color: white;
        background-image: url('${RightActive}');
        @media (max-width:900px) {
          
            background-color:#D9D9D9 !important; 
          }
        
        
    }
    // &:hover{
    //     .next-img{
    //         cursor: pointer;
    //         background-image: url('${RightHover}') !important;
    //     }
    // }
}
.prev-link{
  .prev-img{
      width: 154px;
      height: 53px;
      float:left;
      left: 0px;
      margin-bottom: 10px;
      position: absolute;
      top: 40%;
      cursor: pointer;
      background-color: white;
      background-size: contain;
      background-image: url('${LeftActive}');
      ${media.down('md')} {
        // width: 130.5px;
        // height: 45px;
        left: 12px;
        background-color: white;
        margin-top: -20px;
      }
      @media (max-width:900px) {
          
        background-color:#D9D9D9 !important; 
      }
      @media (max-width:1266px) {
          
        margin-left:10px !important;
      } @media (max-width:999px) {
          
        margin-left:0px !important;
      }
      
      
  }
  // &:hover{
  //     .prev-img{
  //         cursor: pointer;
  //         background-image: url('${LeftHover}') !important;
  //     }
  // }
}
.middle-part{
  .prev-img{
      width: 110px;
      height: 53px;
      float:left;
      right: 371px;
      position: absolute;
      top: 40%;
      background-color: white;
      background-size: contain;
      background-image: url('${Frame}');
      ${media.down('md')} {
        display:none;
        width: 10px;
      }
      
      
  }
}
  .main-img{
    .partner-img{

        width: 802px;
        height: 600px;
        float:left;
        right:0px;
        // border: 3px red solid;
        position: absolute;
        top: 40%;
        background-size: contain;
       // background-image: url('${L2wMain}');
        // ${media.down('md')} {
        //   width: 0px;
        //   height: 0px;
    
        // }
        @media (max-width:830px) {
         
            width: 818px;
        height: auto;
           
          }
          @media (max-width:900px) {
         
            display:none !important;
           
          }
        
    }
  }
  .mobile-img{
    ${media.down('md')} {
      margin-top:-88px;
      padding-top:40px;
      padding-left:10px;
    }
    @media (max-width:900px) {
          
      background-color:#D9D9D9 !important; 
    }
    @media (max-width:1266px) {
          
      padding-left:10px !important;
    }
    
  }
  @media (max-width:1266px) {
    .middle-part{
      display:none;
    }
    
     .next-img{
 
          background-color: white !important;
          right: 12px !important;
          margin-top: -20px !important;
          @media (max-width:1266px) {
            margin-top: 0px !important;
         }
         @media (max-width:1000px) {
          margin-top: -20px !important;
       }
     }
    
    
  }
`;


const CardItem = memo(({ item, isCarousel = false }) => <><Col style={isCarousel ? { width: '700px', height: '600px', float: 'left' } : {}} className={isCarousel ? 'item-testimonials carousel-item mobile-img' : 'carousel-item-mobile carousel-item carousel-item-desktop'}>
  <Card className='card'>
    <Row>
      <Row align='middle' className='card__header'>
        <img src={item.node.frontmatter.logo} className='card__logo' />
      </Row>
      <Row className={'textPartner'}>
        <Text level='t8'> {item.node.frontmatter.content}</Text>
      </Row>
    </Row>
    <Row align='middle' className={'footer'}>
      {/* <Col>
        <img src={item.node.frontmatter.autorImage} width='50' style={{ borderRadius: '100%' }} />
      </Col> */}
      <Col className='card__personal-info'>
        <Row>
          <Text level='t4' colorType='darkGray'>{item.node.frontmatter.autor}</Text>
        </Row>
        <Row>
          <Text level='t7'>{item.node.frontmatter.position}</Text>
        </Row>
      </Col>
    </Row>
  </Card>

</Col>
  <Col className={'main-img'}>
    {/* <div style={{height:'600px'}} className={'partner-img'} /> */}
    <img src={item.node.frontmatter.autorImage} style={{ height: '600px' }} className={'partner-img'} />
  </Col>
</>);

const Testimonials = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
   query Products {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/testimonials/"}}) {
    edges {
      node {
        frontmatter {
            content
          autor
          autorImage
          position
          logo
          logoWidth
        }
      }
    }
  }
}

  `);
  const mainData = allMarkdownRemark.edges;

  const ref = useRef(null);
  return <>
    <GlobalStyle />
    <Row align={'center'} justify={'space-between'}>

      <Col span={24}>
        <Carousel responsive={[
          {
            breakpoint: 2561,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]} infinite={true} ref={ref} className={'carousel-desktop'}  >
          {mainData.map(item => {
            return <CardItem key={Math.random()} item={item} isCarousel />
          })}
        </Carousel>
      </Col>

    </Row>



    <Row align={'center'} justify={'space-between'} style={{ marginTop: '-54px' }}>
      <Row align={'middle'} justify={'space-between'}>
        <Col className={'prev-link'} span={1} onClick={() => ref.current.prev()}>
          <div className={'prev-img'} />
        </Col>
      </Row>
      <Row align={'middle'} justify={'space-between'}>
        <Col className={'middle-part'} span={1}>
          <div className={'prev-img'} />

        </Col>
      </Row>
      <Row align={'middle'} justify={'space-between'}>
        <Col className={'next-link'} onClick={() => ref.current.next()} span={1}>
          <div className={'next-img'} />
          {/* <Button className={'btn'}>next</Button> */}
        </Col>
      </Row>
    </Row>
  </>
};

export default Testimonials;
