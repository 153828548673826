import { Carousel, Row, Col } from 'antd';
import Text from '../Ui/Typography/Text';
import React, { useRef, useState } from 'react';
import { createGlobalStyle } from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby';

import { media } from '../Ui/text-sizes';
import RightActive from '../assets/right-active.png';
import RightHover from '../assets/right-big-hover.png';
import LeftActive from '../assets/left-active.png';
import LeftHover from '../assets/left-big-hover.png';
import RightSmall from '../assets/right-small.png';
import RightSmallActive from '../assets/right-small-hover.png';
import './portfolio.less';

const Portfolios = () => {
    const { allMarkdownRemark } = useStaticQuery(graphql`
        query MyQuery {
            allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/partner/" } }, sort: { fields: [frontmatter___order], order: ASC }) {
                edges {
                    node {
                        frontmatter {
                            order
                            title
                            description
                            id
                            img
                            slug
                        }
                    }
                }
            }
        }
`);

    const mainData = allMarkdownRemark.edges;
    const ref = useRef(null);

    function onChange(a, b, c) {
        setCurrent(a);
    }

    const contentStyle = {
        width: '402px',
        height: '536px',
        borderRadius: 0,
    };

    const [current, setCurrent] = useState(0);

    return <Row style={{width:'100%'}}>
        {/* <Row justify={'end'} style={{ marginBottom: '33px' }}>
            <div onClick={() => ref.current.prev()} className='left-arrow' />
            <div onClick={() => ref.current.next()} className='item-portfolio__right-arrow' />
        </Row> */}
        {/* <Carousel
            autoplay
            autoplaySpeed={7000}
            infinite={true} ref={ref} responsive={
                [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            } variableWidth slidesToShow={1} slidesToScroll={1} afterChange={onChange}> */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
             <Carousel responsive={[
          {
            breakpoint: 2561,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: true,
        centerPadding: '10px',
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerMode: true,
        centerPadding: '10px',
            }
          }
          ]} className={'carousel-desktop'} >
          {mainData.map(item => {
            return <div className='item-portfolio' >
           
            <Row className='item-portfolio__link'>
                <img alt="new" src={item.node.frontmatter.img} style={contentStyle} className='item-portfolio__img' />
            </Row>
            <Row className='item-portfolio__title' justify='center'>
              <Text level='t5' colorType='lightBlack'>
                        <span class="bold-title">{item.node.frontmatter.title}</span>
                    </Text>
                 
            </Row>
            {/* <Row justify='center'>
    <Col xs={24} sm={12} lg={15}>
      <Text level={2} center>
      We form a team of engineers, designers, and experienced advisors to take your idea from prototype to product
      </Text>
      </Col>
  </Row> */}
          
            <Row  className='item-portfolio__description' justify={'center'}>            
                    <Text level='t4' colorType='darkGrayNew'>
                    <span class="bold-title">{item.node.frontmatter.description}</span>
                    </Text>             
            </Row>
        </div>
          })}
        </Carousel>
        </Col>
        {/* </Carousel> */}
        </Row>
};

export default Portfolios;
