import React from 'react';
import Container from '../components/container';
import SEO from '../components/seo';
import Title, { HeroTitle, HeroMainTitle } from '../Ui/Typography/Title';
import Text from '../Ui/Typography/Text';
import { Col, Row } from 'antd';
import Span from '../Ui/Typography/Span';
import Button from '../Ui/Buttons/index';
import Hero from '../components/hero';
import { createGlobalStyle } from 'styled-components';
import Portfolios from '../components/Portfolio';
import Section from '../components/Layout/Section';
import Testimonials from '../components/Testimonials';
import Colors from '../Ui/color';
import { Link } from 'gatsby';
import { media } from '../Ui/text-sizes';
import Content from '../components/Layout/Content';
import animateScrollTo from 'animated-scroll-to';
import TplTeam from '../assets/tpl-team.png';
import TeamRight from '../assets/team-right.png';
import LetsChat from '../assets/lets-chat.png';

const GlobalStyle = createGlobalStyle`
  .tplTeam{
    height: 630px;
    width: auto;
    ${media.down('md')} {
      height: 200px;
      width: auto;
    }
    @media (max-width:1080px) {
      height: auto;
      width: 850px;

    }
    @media (max-width:1030px) {
      height: auto;
      width: 800px;

    }
    @media (max-width:900px) {
      height: auto;
      width: 700px;

    }
    @media (max-width:765px) {
      height: 200px;
      width: auto;

    }
  }
  .testimonial-bg{
    background-color: ${Colors.white};
    @media (max-width:900px) {
      background-color: #D9D9D9;

  }
}
  .letsChat{
    margin-left: 14px;
    z-index: 10;
    margin-top: -550px;
    width: 189px !important;
    ${media.down('sm')} {
      margin-top: 5px;
      margin-bottom: -34px;
    
    }
    ${media.down('md')} {

      margin-top: 5px;
      margin-bottom: -34px;
      
    }
    @media (max-width:1266px) {
      margin-top: -550px;
      padding: 18px;
    }
    @media (max-width:1066px) {
      margin-left: 0px;
      margin-top: -430px;
    }
    @media (max-width:900px) {
      padding-left: 45px;
      margin-left: -37px;
    
    }
    @media (max-width:767px) {
      margin-top: 0px;
    }
  }
  }
  .teamRight{
    height: 630px;
    width: auto;
    padding-left: 28px;
    @media (max-width:1080px) {
      height: 520px;
       width: auto;
      padding-left: 0px;
    }
    @media (max-width:1030px) {
      height: 489px;
       width: auto;
      padding-left: 0px;
    }
    @media (max-width:900px) {
      height: 428px;
       width: auto;
      padding-left: 0px;
    }
    @media (max-width:767px) {
      height: 0px;
       width: auto;
      padding-left: 0px;
    }
   
  
  }`;
const IndexPage = ({ data }) => (
  <>
  <GlobalStyle />
  <Container type='transparent'>
    <SEO title='Tidepool Labs' />
    <Hero onPress={() => animateScrollTo(document.querySelector('.my-element'))}>
      <Col xs={24} sm={24} md={22} lg={22} xl={18}>
        <HeroMainTitle>We work with founders to <span class="bold-millik">build</span> and <span class="bold-millik">launch</span> companies from scratch</HeroMainTitle>
      </Col>
   
      <Col xs={24} sm={22} lg={16}>
        <Text level='t6' colorType='white'>
        Tidepool Labs is a venture studio
        </Text>
   
      </Col>
      {/* <Col xs={24} sm={15} lg={24}>
        <HeroTitle level={2} color='white'>
          Now make it happen by partnering with our Startup Studio and go
          <Span type='gradient2' mobile> from zero to launch.</Span>
        </HeroTitle>
      </Col> */}
    </Hero>
    <Section spaceBottom={2} mobileBottomSpace={12} className={'my-element'}>
      {/* <Text level='t4' upperSeperators colorType='orange'>
        Portfolio
      </Text> */}
      <Col xs={24} sm={12} lg={18} xl={13}>
        <Title level={2} color='darkGray' topSpace={29} bottomSpace={34} mobileBottomSpace={20} letterMd>
          We partner with founders to bring
          <Span type='gradient2'> <span class="bold">ideas</span> </Span>
          to life.
        </Title>
      </Col>
      {/* <Col xs={24} sm={20} lg={16}>
        <Text level='t4' colorType='black'>
          We serve as your interim founding engineering team to take your idea from prototype to product.
        </Text>
      </Col> */}
    </Section>
    <div className={'container-hero'}>
      <Portfolios data={data} />
    </div>
    {/* <Section align='center' mobileBottomSpace={70} mobileTopSpace={30} spaceTop={67}>
      <Link to='/our-portfolio/'>
        <Button type='primary' shape='round'>See All Companies</Button>
      </Link>
    </Section> */}
    <Section spaceBottom={63} mobileBottomSpace={0}>
     
      
    </Section>
    <div className={'testimonial-bg'}>
      <Content  paddingBottom={120} mobileSpacing={1} mobileBottomSpacing={54}>
        <Testimonials />
      </Content>
    </div>


    <Section bottomdark spaceBottom={66} mobileBottomSpace={53} mobileTopSpace={5} align='center'>

      <Col xs={24} sm={24} lg={24}>
      
        <Title level={2} color='white' topSpace={19} letterMd center>
        Your <Span style={{margin:'20px', height:'200px'}} type='gradient2'><span class="bold" >cofounding</span></Span> team
          
        </Title>
       
  <Row justify='center'>
    <Col xs={24} sm={12} lg={15}>
      <Text level={2} center>
      We form a team of engineers, designers, and experienced advisors to take your idea from prototype to product
      </Text>
      </Col>
  </Row>
      </Col >
      
   
    <div style={{ backgroundColor: Colors.lightBlack, marginTop:'20px' }}>
      <Row>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          {/* <img src="https://assets.tidepoollabs.com/outbound-screen-1.png" alt="Tidepool Labs" /> */}
          <img src={TplTeam} alt="Tidepool Labs" className='tplTeam'/>
         </Col>
         <Col xs={24} sm={24} md={6}  lg={6} xl={6}>
          {/* <Row> */}
           <img src={TeamRight} alt="Tidepool Labs" className='teamRight'/>
           <a className='letsChat' target='blank' href={'mailto:team@tidepoollabs.com'}>
                        <img alt="new" src={LetsChat} className='letsChat'/>
                    </a>
                    {/* </Row> */}
         </Col>
      </Row>
    </div>


    </Section>
  </Container >
  </>
);

export default IndexPage;

